import { createSlice, current } from "@reduxjs/toolkit";
import { ORDER_STORE_NAME } from "../../../contants/stores";
import { Order } from "../types/order.data";
import { OrderStatus } from "../types/order.status";
import { OrderTimeLine } from "../types/order.timeLine";
import { OrderPayment } from "../types/order.payment";

interface OrderState {
  orders: Order[];
  orderTimeLine: OrderTimeLine[];
  orderPayments: OrderPayment[]
  selectedOrderId: string;
  orderStatuses: OrderStatus[];
  primaryStatuses: any[];
  selectedPrimaryStatus: { show: boolean; status: string };
  selectedSubStatuses: any;
  status: string;
  fleetConfig: any;
  businessConfig: any;
  compensationConfig: any;
  cancellationReasons: any;
  delayReasons: string[];
  filters: any;
  activeMode: string;
  searchQuery: string;
  searchOrder: any;
  areas: any;
  error: Error | undefined;
}

export const initialState: OrderState = {
  orders: [],
  orderTimeLine: [],
  orderPayments: [],
  selectedOrderId: "",
  orderStatuses: [],
  primaryStatuses: [],
  selectedPrimaryStatus: {
    show: true,
    status: "PENDING",
  },
  selectedSubStatuses: {},
  areas: [],
  fleetConfig: {},
  businessConfig: {},
  compensationConfig: {},
  cancellationReasons: {},
  delayReasons: [],
  filters: {
    orderInfo: {
      label: 'Info',
      options: [
        {
          path: "isGrouped",
          value: "Hide Group",
          checked: false,
        },
        {
          path: "isBulk",
          value: "Hide Bulk",
          checked: false,
        },
      ]
    },
    orderType: {
      label: 'Type',
      options: [
        {
          value: "Buy",
          checked: false,
        },
        {
          value: "Pickup",
          checked: false,
        },
        {
          value: "Seller",
          checked: false,
        },
        {
          value: "Courier",
          checked: false,
        },
      ]
    },
    tag: {
      label: 'Tags',
      options: [
        {
          value: "CB",
          checked: false,
        },
        {
          value: "Bike",
          checked: false,
        },
      ]
    },
    validation: {
      label: 'Validation',
      options: [
        {
          value: "Validated",
          checked: false,
        },
        {
          value: "Not validated",
          checked: false,
        },
      ]
    },
    priority: {
      label: 'Priority',
      options: [
        {
          path: "FTO",
          value: "FTO",
          checked: false,
        }
      ]
    },
    scheduled: {
      label: 'Schedule',
      options: [
        {
          value: "Instant",
          checked: false,
        },
        {
          value: "Scheduled",
          checked: false,
        }
      ]
    },
  },
  activeMode: 'all',
  searchQuery: '',
  searchOrder: {},
  status: "idle",
  error: undefined,
};

export const slice = createSlice({
  name: ORDER_STORE_NAME,
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setSelectedOrderId: (state, orderId: any) => {
      state.selectedOrderId = orderId.payload;
    },
    setPrimaryStatuses: (state, action) => {
      state.primaryStatuses = action.payload;
    },
    setOrderStatuses: (state, action) => {
      state.orderStatuses = action.payload;
    },
    setSelectedSubStatuses: (state, action) => {
      state.selectedSubStatuses = action.payload;
    },
    setSelectedPrimaryStatus: (state, action) => {
      state.selectedPrimaryStatus = action.payload;
    },
    addNewOrder: (state, action) => {
      state.orders.push({
        ...action.payload.data,
        id: action.payload.id,
      })
    },
    updateOrder: (state, action) => {
      let orderIndex = state.orders.findIndex(
        (o) => o.id === action.payload.id
      );
      // console.log('as', current(state.orders).find(o => o.id === action.payload.id))
      if (orderIndex >= 0) {
        state.orders.splice(orderIndex, 1, {
          ...action.payload.data,
          id: action.payload.id,
        });
      }
      // console.log('as2', current(state.orders).find(o => o.id === action.payload.id))
    },
    setOrderTimeLine: (state, action) => {
      state.orderTimeLine = action.payload;
    },
    setOrderPayments: (state, action) => {
      state.orderPayments = action.payload;
    },
    setAreas: (state, action) => {
      state.areas = action.payload;
    },
    setFleetConfig: (state, action) => {
      state.fleetConfig = action.payload;
    },
    setBusinessConfig: (state, action) => {
      state.businessConfig = action.payload;
    },
    setCompensationConfig: (state, action) => {
      state.compensationConfig = action.payload;
    },
    setcancellationReasons: (state, action) => {
      state.cancellationReasons = action.payload;
    },
    setDelayReasons: (state, action) => {
      state.delayReasons = action.payload;
    },
    setAppliedFilters: (state, action) => {
      state.filters = action.payload;
    },
    setActiveMode: (state, action) => {
      state.activeMode = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setSearchOrder: (state, action) => {
      state.searchOrder = action.payload;
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;

