import { MutableRefObject, useEffect, useRef, useState } from "react";
import { AGENT_STORE_NAME, AUTH_STORE_NAME, ORDER_STORE_NAME } from "../../../../../contants/stores";
import { useAppSelector } from "../../../../../redux";
import { addDelayToOrder, cancelOrder, compensate, convertToBuy, rescheduleCourierOrder, transferOrders, unssignBuddy, validateOrder } from "../../../actions/order.actions";
import Modal from "../../../../../components/Dialog/Modal";
import { Dropdown } from "../../../../../components/Dropdown/Dropdown";
import Button from "../../../../../components/Button/Button";
import { formatDate, selectContents } from "../../../../../utils/helpers";
import { clear, getCancellationReasons, getDelayReasons, getSuccessfulOrderPayments } from "../../../actions/config.actions";
import { updatePinnedOrders } from "../../../../agent/actions/agent.actions";
import { Agent } from "../../../../agent/types/agent.data";
import { ActionsDropdown } from "../../../../../components/Dropdown/ActionsDropdown";
import { OrderCompensationModal } from "./models/orderCompensation";
import { OrderTransferDialogContent } from "./models/orderTransfer";
import { OrderValidateDialogContent } from "./models/orderValidate";
import { CancelDialogContent } from "./models/orderCancel";
import { DelayDialogContent } from "./models/orderDelay";
import { OrderPayments } from "./models/orderPayments";

const delayTimeOptions = [
  {
    value: 5,
    label: "5 Minutes",
  },
  {
    value: 10,
    label: "10 Minutes",
  },
  {
    value: 15,
    label: "15 Minutes",
  },
  {
    value: 20,
    label: "20 Minutes",
  },
  {
    value: 25,
    label: "25 Minutes",
  },
  {
    value: 30,
    label: "30 Minutes",
  },
];

export default function OrderSummary(props: any) {

  const submitButtonRef = useRef() as MutableRefObject<{ click: Function }>;
  const agent = useAppSelector(state => state[AUTH_STORE_NAME].loggedInAgent);
  const agents = useAppSelector(state => state[AGENT_STORE_NAME].agents.filter((agent: Agent) => agent.status === 'ONLINE' && agent.id !== props?.order?.agent?.id && !(agent.isAdmin || agent.isSuperAdmin)));
  const orderStatuses = useAppSelector(state => state[ORDER_STORE_NAME].orderStatuses);
  const cancellationReasons = useAppSelector(state => props?.order?.orderType === 'COURIER' ? state[ORDER_STORE_NAME].cancellationReasons.courier : state[ORDER_STORE_NAME].cancellationReasons.app);
  const delayReasons = useAppSelector(state => state[ORDER_STORE_NAME].delayReasons);
  const orderStatus = orderStatuses.find((st: any) => st.primaryStatus === props?.order?.primaryStatus && st.subStatus === props?.order?.subStatus)
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [selectedCancellationReason, setSelectedCancellationReason] = useState('');
  const [selectedDelayTime, setSelectedDelayTime] = useState(5);
  const [selectedDelayReason, setSelectedDelayReason] = useState({});
  const [selectedAgent, setSelectedAgent] = useState(agents[0]);
  const [orderValidation, setOrderValidation] = useState()
  const [noteList, setNoteList] = useState(['']);
  const [compensation, setCompensation] = useState()
  const date = `${formatDate(props?.order?.deliveryAt)} `;

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      await getCancellationReasons();
      await getDelayReasons();
      // await getSuccessfulOrderPayments(props?.order?.id)
    }
    fetchData().catch(console.error);
    console.log(props.order)
  }, []);

  useEffect(() => {

    setSelectedDelayTime(delayTimeOptions[0].value)
    if (cancellationReasons) {
      setSelectedCancellationReason(cancellationReasons[0]);
    }

    if (delayReasons) {
      setSelectedDelayReason(delayReasons[0]);
    }

  }, [cancellationReasons, delayReasons]);

  useEffect(() => {
    setSelectedAgent(agents[0]);
    setOrderValidation(props?.order?.orderValidation);
    setNoteList(props?.order?.notes);
  },[props?.order?.id, openModal]);

  const confirm = async () => {
    // convert to switch
    switch (modalTitle) {
      case 'Cancel':
        await cancelOrder(props?.order?.orderNo, props?.order?.id, selectedCancellationReason);
        break;
      case 'Unassign':
        await unssignBuddy(props?.order?.orderNo, props?.order?.deliveryInfo?.deliveryNo, props?.order?.id);
        break;
      case 'Convert to buy':
        await convertToBuy(props?.order?.id, props?.order?.orderNo);
        break;
      case 'Add delay to':
        addDelayToOrder(props?.order?.id, props?.order?.deliveryAt, selectedDelayTime, selectedDelayReason)
        break;
      case 'Transfer':
        await transferOrders(props?.order?.agent, selectedAgent ?? null, [props.order], 'TRANSFER');
        break;
      case 'Validate':
        const notesUpdated = JSON.stringify(props?.order?.notes) !== JSON.stringify(noteList);
        let difference = noteList.filter(x => !props?.order?.notes.includes(x));
        const validationUpdated = JSON.stringify(props?.order?.orderValidation) !== JSON.stringify(orderValidation);
        await validateOrder(props?.order?.id, props?.order?.orderNo, noteList, orderValidation, notesUpdated, difference, validationUpdated, props?.order?.deliveryInfo?.fleetId,
          props?.order?.user?.id, props?.order?.pickupAddress?.id, props?.order?.deliveryAddress?.id, props?.order?.pickupAddress?.isValid, props?.order?.deliveryAddress?.isValid);
        break;
      case 'Compensate':
        await compensate(props?.order?.id, props?.order?.orderNo,compensation, props?.order?.user);
        break;
      case 'Reschedule':
        await rescheduleCourierOrder(props?.order?.id, props?.order?.orderNo);
        break;
      default:
        break;
    }
    cancel();
  }

  const cancel = () => {
    setOpenModal(false);
    setModalTitle('');
    setSelectedDelayReason(delayReasons[0]);
    setSelectedCancellationReason(cancellationReasons[0]);
    setSelectedDelayTime(delayTimeOptions[0]?.value);
  }

  const pinOrder = () => {
    const value = agent?.pinnedOrders?.includes(props?.order?.orderNo);
    if (!value) {
      let pinnedOrders = [...agent?.pinnedOrders];
      pinnedOrders.push(props?.order?.orderNo);
      updatePinnedOrders(agent?.id, pinnedOrders);
    }
  }

  useEffect(() => {
    if(!openModal) clear();
  },[openModal]);

  return (
    <>
      <div className="px-3 py-1 mx-3 mt-3 border border-light rounded-lg">
        <div className="flex items-center justify-between font-bold border-b border-light pb-2 mb-2 text-base">
          <div className="flex">
            <div className="flex">
              <h1 id="orderNo" className="text-left font-bold" tabIndex={-1}>
                {props?.order?.orderNo}
              </h1>
              <svg onClick={() => {
                selectContents("orderNo");
                navigator.clipboard.writeText(props?.order?.orderNo);
              }}
                className="mx-2 cursor-pointer w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"></path></svg>
            </div>

            {props?.order?.orderType !== "COURIER" && props?.order?.deliveryInfo?.deliveryNo ?
              <div className="flex">
                <h1 id="deliveryNo" className="text-left font-bold" tabIndex={-1}>
                  {"  |  " + props?.order?.deliveryInfo?.deliveryNo}
                </h1>
                <svg onClick={() => {
                  selectContents("deliveryNo");
                  navigator.clipboard.writeText(props?.order?.deliveryInfo?.deliveryNo)
                }}
                  className="mx-2 cursor-pointer w- h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"></path></svg>
              </div>
              : <></>}
            {!props?.order?.compensation ? <></> : <p className="text-red-400">Compensated</p>}
          </div>
          <div className="flex items-center h-8">
            {props?.order?.priorityStatus?.length && props?.order?.primaryStatus !== 'OTHERS' ?
              <ActionsDropdown id={props?.order?.id} priorityStatus={props?.order?.priorityStatus} orderNo={props?.order?.orderNo}/>
            : <></>}
            <button onClick={() => pinOrder()} className={`inline-flex justify-center rounded-md p-1 text-sm font-medium ${agent.pinnedOrders?.includes(props?.order?.orderNo) ? "text-indigo-400 hover:text-opacity-50 hover:bg-indigo-100" : "text-gray-900 hover:text-opacity-50 hover:bg-gray-100"} focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
              <svg className="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path fill="currentColor" d="M18 3v2h-1v6l2 3v2h-6v7h-2v-7H5v-2l2-3V5H6V3z" />
                </g>
              </svg>
            </button>
              <Dropdown setOpenModal={setOpenModal} setModalTitle={setModalTitle} subStatus={props?.order?.subStatus} tripInfo={props?.order?.tripInfo}
              b2bOrderType={props?.order?.b2bOrderType} deliveryStatus={props?.order?.deliveryInfo?.deliveryStatus} isAlreadyRescheduled={props?.order?.isAlreadyRescheduled}
              orderType={props?.order?.orderType} fleetId={props?.order?.deliveryInfo?.fleetId} deliveryNo={props?.order?.deliveryInfo?.deliveryNo}
              isAgent={!agent.isAdmin && !agent.isSuperAdmin} primaryStatus={props?.order?.primaryStatus} isCompensated={props?.order?.compensation ? true : false}/>
          </div>
        </div>

        <div className="flex justify-between mb-2 text-sm">
          <div className="flex items-center">
            <p className="w-20">Order type</p>
            :<span className="ml-2">{props?.order.isScheduled ? `${props?.order?.orderType} / Scheduled` : props?.order?.orderType}</span>
          </div>
          <span style={{ "--status-color": orderStatus?.color, backgroundColor: `${orderStatus?.color}30` } as React.CSSProperties}
            className="flex items-center text-sm font-bold text-[var(--status-color)] rounded-lg px-3 py-1">{props?.order?.subStatus}</span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <div className="flex items-center">
            <p className="w-20">Agent</p>
            :<span className="ml-2">{props?.order?.agent?.name}</span>
          </div>
        </div>
        {props?.order?.subStatus === 'CANCELLED' ?
          <div className="flex justify-between mb-2 text-sm">
            <div className="flex items-center">
              <p className="w-20">Cancel info</p>
              :<span className="ml-2">{props?.order?.cancelInfo?.reason} | {formatDate(props?.order?.cancelInfo?.cancelledAt)}</span>
            </div>
          </div>
          : <></>}
        {props?.order?.refundInfo !== null && props?.order?.orderType !== 'COURIER' ?
        <div className="flex justify-between mb-2 text-sm">
          <div className="flex items-center">
            <p className="w-20">Refund status</p>
            :<span className="ml-2">{props?.order?.refundInfo?.status}</span>
          </div>
          <div className="flex items-center">
            <p className="w-20">reason</p>
            :<span className="ml-2">{props?.order?.refundInfo?.reason}</span>
          </div>
          <div className="flex items-center">
            <p className="w-20">amount</p>
            :<span className="ml-2">{props?.order?.refundInfo?.amount}</span>
          </div>
        </div> : <></>}
        <div className="flex justify-between mb-2 text-sm">
          <div className="flex items-center text-sm">
            <p className="w-20">Delivery</p>
            :<span className="ml-2">{formatDate(props?.order?.deliveryAt)}</span>
          </div>
          {props?.order?.orderType !== 'COURIER' && props?.order?.primaryStatus !== 'OTHERS' ?
            <div onClick={() => {
              setOpenModal(true);
              setModalTitle('Add delay to')
            }}
              className="flex gap-1 mt-1 cursor-pointer text-indigo-400 hover:text-indigo-300">
              <span className="mr-1 text-xs">Add delay</span>
              <svg
                className="mr-1 text-xs fill-indigo-400 hover:text-indigo-300"
                x="0px"
                y="0px"
                width="1rem"
                height="1rem"
                viewBox="0 0 567.22 567.22"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <g>
                      <path
                        d="M560.433,142.569L447.613,29.751c-4.339-4.339-10.247-6.787-16.396-6.787c-6.13,0-12.038,2.448-16.377,6.787
          l-20.198,20.218c-9.064,9.056-9.064,23.727,0,32.775l16.766,16.777l-19.534,19.535c-32.2-17.615-69.132-27.668-108.349-27.668
          c-39.18,0-76.053,10.017-108.22,27.604l-19.48-19.468l16.765-16.777c9.066-9.047,9.066-23.717,0-32.775l-20.198-20.218
          c-4.339-4.339-10.245-6.787-16.377-6.787c-6.148,0-12.056,2.448-16.396,6.787L6.795,142.569C2.456,146.908,0,152.808,0,158.957
          c0,6.149,2.456,12.048,6.795,16.388l20.217,20.218c9.049,9.039,23.708,9.039,32.774-0.008l18.407-18.409l16.304,16.314
          c-23.578,35.73-37.409,78.438-37.409,124.357c0,124.864,101.594,226.438,226.43,226.438c124.856,0,226.43-101.571,226.43-226.438
          c0-45.876-13.813-88.539-37.337-124.25l16.416-16.424l18.406,18.409c9.065,9.047,23.729,9.047,32.774,0.008l20.219-20.218
          c4.339-4.339,6.793-10.239,6.793-16.388C567.226,152.808,564.77,146.908,560.433,142.569z M283.519,489.357
          c-94.572,0-171.533-76.949-171.533-171.541c0-94.584,76.961-171.536,171.533-171.536c94.575,0,171.554,76.951,171.554,171.536
          C455.073,412.409,378.096,489.357,283.519,489.357z"
                      />
                      <path
                        d="M362.604,290.375h-51.608v-51.636c0-15.157-12.296-27.446-27.457-27.446c-15.141,0-27.436,12.287-27.436,27.446v51.636
          h-51.645c-15.162,0-27.457,12.279-27.457,27.439c0,15.167,12.295,27.446,27.457,27.446h51.645v51.636
          c0,15.157,12.295,27.446,27.436,27.446c15.161,0,27.457-12.289,27.457-27.446v-51.636h51.608
          c15.161,0,27.457-12.279,27.457-27.446C390.061,302.655,377.765,290.375,362.604,290.375z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div> : <></>}
        </div>

      </div>

      {openModal ?
        <Modal title={modalTitle !== "Payments" ? `${modalTitle} order` : `Order payments` } onClose={() => setOpenModal(false)}>
          <form className="bg-white p-2 rounded-lg" onSubmit={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            submitButtonRef?.current?.click();
            
          }}>
            {modalTitle === "Cancel" ?
              <CancelDialogContent selectedCancellationReason={selectedCancellationReason} 
                cardCharges={props?.order?.orderInfo?.cardCharges} orderType={props?.order?.orderType}
                setSelectedCancellationReason={setSelectedCancellationReason} /> :
                modalTitle === "Add delay to" ?
                <DelayDialogContent delayTimeOptions={delayTimeOptions} date={date} orderDelays={props?.order?.orderDelays}
                  selectedDelayTime={selectedDelayTime} selectedDelayReason={selectedDelayReason}
                  setSelectedDelayReason={setSelectedDelayReason} setSelectedDelayTime={setSelectedDelayTime} /> :
                  modalTitle === "Transfer" ?
                  <OrderTransferDialogContent agents={agents} selectedAgent={selectedAgent} setSelectedAgent={setSelectedAgent}/> :
                  modalTitle === "Validate" ?
                  <OrderValidateDialogContent notes={noteList} orderValidation={orderValidation} primaryStatus={props?.order?.primaryStatus} setNoteList={setNoteList} setOrderValidation={setOrderValidation} /> :
                  modalTitle === "Compensate" ?
                  <OrderCompensationModal setCompensation={setCompensation} deliveryFee={props?.order?.orderInfo?.deliveryFee} userPhone={props?.order?.user?.phone} deliveryNo={props?.order?.deliveryInfo?.deliveryNo}/> :
                  modalTitle === "Payments" ?
                  <OrderPayments orderId={props?.order?.id}/> :
                    <div className="mx-3 my-2">
                      {`Please confirm the action ${modalTitle} order.`}
                    </div>}
            {((modalTitle === "Transfer" && !agents.length) || modalTitle === "Payments") ? <></> :
              <div className="flex items-center justify-end mx-3 my-2 gap-3">
                <button onClick={() => { cancel() }}
                  className="rounded-md w-full border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                >
                  Cancel
                </button>
                <Button
                  ref={submitButtonRef}
                  type="submit"
                  notify={false}
                  id="editSummary"
                  cb={confirm}
                  text="Confirm"
                  loadingText="Confirming..."
                  successMsg={modalTitle === 'Cancel' ? 'Cancelled successfully' : "Changes saved successfully"}
                />
              </div>}
          </form>

        </Modal> : null}
    </>
  )
}








